
import topNav from "@/components/home/topNav.vue";
import bottomNav from "@/components/home/bottomNav.vue";
import {defineComponent, onMounted} from "vue";

export default defineComponent({
  name: "Container",
  setup(){
    onMounted(()=>{
      //
    });

    return {}
  },
  components: {
    topNav,
    bottomNav
  }
})
